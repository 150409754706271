<template>
    <div :class="{ 'document-preview content-wrapper':true, 'h-100': !isFullPageView }">
        <rq-no-data v-if="showNoDocs" text="No valid documents available to view.">
            <router-link class="btn btn-link btn-theme" to="/documents/generate">Go to Document Generation</router-link>
        </rq-no-data>
        <template v-else>
            <nav class="navbar bg-light navbar-expand doc-viewer-toolbar">
                <div class="container-fluid">
                    <ul v-if="loadFailed"
                        class="navbar-nav">
                        <li class="nav-item">
                            <a href="#" class="nav-link error-alert"
                                @click.prevent="loadProgressActive=true">
                                <FontAwesomeIcon icon="fas fa-exclamation-triangle" />
                                <span v-if="allLoadFailed">All documents failed to generate.</span>
                                <span v-else>One or more documents failed to generate.</span>
                            </a>
                        </li>
                    </ul>
                    <ul v-if="!allLoadFailed" class="navbar-nav ms-auto">
                        <template v-if="showStatusDropdown">
                            <li class="navbar-item pt-2">
                                <b-form-checkbox v-model="activeDocument.applyWatermark" @change="onApplyWatermarkChange">Apply Watermark</b-form-checkbox>
                            </li>
                            <li class="navbar-item">
                                <dx-select-box
                                    id="drp_watermark"
                                    value-expr="id"
                                    display-expr="name"
                                    search-expr="name"
                                    :search-enabled="true"
                                    :items="watermarkItems"
                                    v-model="activeDocument.documentWatermarkTextID"
                                    @valueChanged="onSelectedWatermarkChange"
                                />
                            </li>
                            <li class="navbar-item">
                                <b-btn variant="dark" @click="onToggleWatermarkPreview" :disabled="!watermarkOptionSelected">
                                    <span>{{isPreviewingWatermark ? 'Hide':'Show'}} Preview</span>
                                </b-btn>
                            </li>
                            <li class="navbar-divider">&nbsp;</li>
                        </template>
                        <li class="navbar-item">
                            <b-dropdown text="Save" v-if="!hasChecks" variant="dark" menu-class="py-2" :disabled="loadFailed" right>
                                <b-dropdown-group header="To Document Mgmt As..." v-if="localSecurity.FileScanCanAttachFilesAndDocuments">
                                    <b-dropdown-item-button @click="onSaveClick(DOC_FILE_TYPES.DOCX, SAVE_DOC_LOCATIONS.DocumentManagement)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onSaveClick(DOC_FILE_TYPES.PDF, SAVE_DOC_LOCATIONS.DocumentManagement)">PDF</b-dropdown-item-button>
                                </b-dropdown-group>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-group header="To My Computer As...">
                                    <b-dropdown-item-button @click="onSaveClick(DOC_FILE_TYPES.DOCX, SAVE_DOC_LOCATIONS.Local)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onSaveClick(DOC_FILE_TYPES.PDF, SAVE_DOC_LOCATIONS.Local)">PDF</b-dropdown-item-button>
                                </b-dropdown-group>
                            </b-dropdown>
                        </li>

                        <li v-show="documents.length > 1" v-if="!hasChecks" v-rq-tooltip="saveAllTooltip" class="navbar-item">
                            <b-dropdown text="Save All" variant="dark" menu-class="py-2" :disabled="loadFailed" right>
                                <b-dropdown-group header="To Document Mgmt As..." v-if="localSecurity.FileScanCanAttachFilesAndDocuments">
                                    <b-dropdown-item-button @click="onSaveAllClick(DOC_FILE_TYPES.DOCX, SAVE_DOC_LOCATIONS.DocumentManagement)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onSaveAllClick(DOC_FILE_TYPES.PDF_COMBINED, SAVE_DOC_LOCATIONS.DocumentManagement)">PDF (combined)</b-dropdown-item-button>
                                </b-dropdown-group>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-group header="To My Computer As...">
                                    <b-dropdown-item-button @click="onSaveAllClick(DOC_FILE_TYPES.DOCX, SAVE_DOC_LOCATIONS.Local)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onSaveAllClick(DOC_FILE_TYPES.PDF, SAVE_DOC_LOCATIONS.Local)">PDF</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onSaveAllClick(DOC_FILE_TYPES.PDF_COMBINED, SAVE_DOC_LOCATIONS.Local)">PDF (combined)</b-dropdown-item-button>
                                </b-dropdown-group>
                            </b-dropdown>
                        </li>

                        <li class="navbar-item" v-if="!hasChecks">
                            <b-dropdown text="Email" variant="dark" menu-class="py-2" :disabled="loadFailed" right>
                                <b-dropdown-group header="Document as...">
                                    <b-dropdown-item-button @click="onEmailClick(DOC_FILE_TYPES.DOCX)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onEmailClick(DOC_FILE_TYPES.PDF)">PDF</b-dropdown-item-button>
                                </b-dropdown-group>
                                <b-dropdown-divider v-if="documents.length > 1"></b-dropdown-divider>
                                <b-dropdown-group v-if="documents.length > 1" header="All documents as...">
                                    <b-dropdown-item-button @click="onEmailAllClick(DOC_FILE_TYPES.DOCX)" v-show="!localSecurity.RestrictSendToOptionsToPDFEntry">DOCX</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onEmailAllClick(DOC_FILE_TYPES.PDF)">PDF</b-dropdown-item-button>
                                    <b-dropdown-item-button @click="onEmailAllClick(DOC_FILE_TYPES.PDF_COMBINED)">PDF (combined)</b-dropdown-item-button>
                                </b-dropdown-group>
                            </b-dropdown>
                        </li>

                        <li class="navbar-item">
                            <b-dropdown v-if="documents.length > 1" text="Print" variant="dark" menu-class="py-2" :disabled="loadFailed" right>
                                <b-dropdown-item-button @click="onPrintClick()">Current Document</b-dropdown-item-button>
                                <b-dropdown-item-button @click="onPrintClick(true)">All Documents</b-dropdown-item-button>
                            </b-dropdown>
                            <b-btn v-else variant="dark" @click="onPrintClick()">Print</b-btn>
                        </li>
                    </ul>
                </div>
            </nav>

            <div class="doc-viewer-row">
                <document-nav
                    v-model:items="navItems"
                    v-model:selectedIndex="currentIndex"
                    @change="onNavChange"
                    @orderChange="onNavOrderChange"
                    @closeItem="onNavCloseItem"
                    sortable
                    closeable
                />
                <div class="doc-viewer-container">
                    <rq-document-editor
                        v-if="!allLoadFailed"
                        v-show="!isPreviewingWatermark"
                        ref="documentEditor"
                        v-model:document-name="currentDocumentName"
                        v-model:file-type="currentDocumentFileType"
                        :read-only="isReadOnly"
                        @created="onEditorCreated"
                        @document-changed="onContentChange"
                        @document-loaded="onDocumentLoaded"
                        show-insert-doc-link
                    />
                    <rq-pdf-viewer
                        v-if="hasDocTemplates"
                        v-show="isPreviewingWatermark"
                        ref="pdfViewer"
                    />
                    <rq-pdf-printer ref="pdfViewerPrintAll" />
                </div>
            </div>
        </template>

        <load-progress
            ref="loadProgress"
            title="Loading Documents..."
            :abortOptions="abortLoadOptions"
            :items="navItems"
            v-model:visible="loadProgressActive"
            @ignore="onLoadProgressIgnore"
            @retry="onLoadProgressRetry"
            @complete="onLoadProgressComplete"
        />
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import {
        DocumentModel, DocumentEditorModel, SaveDocumentViewModel,
        DocumentSaveRequest, MergeRequest, MergeResult, FileScanDocumentSaveRequest,
        DocumentFileType, TabItem, ProgressItem, DocNavItemModel
    } from "../models";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { SaveDocumentLocation } from "../enums";
    import { FileScanDocumentAction } from "../../document-mgmt/enums";
    import { CheckPrintingOptions, CheckPrintingOptionsForUser } from "@config/enums";

    import DocumentNav from "../components/DocumentNav.vue";
    import LoadProgress from "../components/LoadProgress.vue";
    import SaveDocumentsDialog from "../components/SaveDocumentsDialog.vue";
    import {
        RqDocumentEditor,
        RqPdfViewer,
        RqPdfPrinter,
        EmailForm
    } from "@/shared/components/rq/";

    import { Actions as HeaderActions } from "@/router/actions/documents";
    import { HudStatusDto } from "@config/ssAndCalcs/models";
    import { SETTLEMENT_TYPE } from "@settlement/models";
    import { SettlementDocTemplateDto } from "../../../modules/file/settlement/components/dashboard/models";
    export default {

        name: "DxDocumentViewer",
        components: {
            DocumentNav,
            LoadProgress,
            RqDocumentEditor,
            RqPdfViewer,
            RqPdfPrinter
        },
        props: {
            documentDescription: { type: String,  default: "" },
        },
        data () {
            return {
                progressDialogId: "",
                // readOnlyDocument: false,
                viewModel: new DocumentEditorModel(),
                documents: [],
                navItems: [],
                hiddenActions: [],
                currentIndex: 0,
                currentDocumentName: "untitled",
                currentDocumentFileType: DocumentFileType.DOCX,
                saveDocumentViewModel: new SaveDocumentViewModel(),
                editorInitialized: false,
                closeHoverActive: false,
                documentsLoaded: false,
                hasChecks: false,
                hasDepositSlips: false,
                isDocEditorReadOnly: false,
                loadProgressActive: false,
                loadError: false,
                abortLoadOptions: {
                    abort: false,
                    message: ""
                },
                showNoDocs: false,
                docContainerStyle: null,
                loadedPdfWatermarkId: -1,
                editorSyncInProgress: false,
                editorSyncIndex: -1,
                watermarkStatuses: [],
                watermarkStatusOptions: []
            };
        },

        computed: {
            ...mapState({
                orderId: state => state.orders.orderId,
                systemDefaults: state => state.system.systemDefaults,
                user: state => state.authentication.session.user,
                orderSummary: state => state.orders.orderSummary,
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                settlementStatementDocuments: state => state.system.settlementStatementDocuments,
                isP2AB: state => _.getBool(state, "orders.orderSummary.useTemplatePage2A2B"),
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                includeAlta: state => _.getBool(state, "orders.orderSummary.includeAlta"),
            }),
            ...mapGetters([
                "getDocumentEditorModel",
                "orderSettings"
            ]),
            localSecurity(){
                return this.securitySettings.findValues([
                    "FileScanCanAttachFilesAndDocuments",
                    "FileScanCheckCategory",
                    "RTFReadOnlyDocuments",
                    "SendChecksToFileScan",
                    "RestrictSendToOptionsToPDFEntry"
                ]);
            },
            isFullPageView() { return this.$route.name === "docs:viewer"; },
            allLoadFailed() { return _.every(this.documents, d => d.hasLoadError); },
            loadFailed() { return _.some(this.documents, d => d.hasLoadError); },
            documentEditorInstance() { return _.get(this, "$refs.documentEditor", null); },
            pdfViewerInstance() { return _.get(this, "$refs.pdfViewer", null); },
            pdfViewerPrintAllInstance() { return _.get(this, "$refs.pdfViewerPrintAll", null); },
            activeDocument() { return this.documents[this.currentIndex] || {}; },
            watermarks() { return _.map(this.activeDocument.watermarks, w => ({ id:w.documentWatermarkTextID, name: w.watermarkText })); },
            hasWatermark() { return _.getBool(this, "activeDocument.hasWatermark"); },
            anyHasWatermark() { return _.some(this.documents, d => _.parseBool(d.hasWatermark)); },
            isPreviewingWatermark() {
                return  _.parseBool(this.activeDocument.watermarkPreviewVisible)
                    && _.parseBool(this.activeDocument.applyWatermark);
            },
            activeWatermarkIsLoaded() { return this.activeDocument.documentWatermarkTextID === this.loadedPdfWatermarkId; },
            comingSoonTooltip() {
                return { boundary:"window", placement:"top", trigger: "hover", title: "Coming Soon" };
            },
            saveAllTooltip() {
                let title = this.loadFailed ? "Cannot save all with failed documents." : "";
                return { boundary:"window", placement:"top", trigger: "hover", title };
            },
            isReadOnly() {
                const self = this;
                if (self.hasChecks)
                {
                    if(self.user.checkDisplay=== CheckPrintingOptionsForUser.UseSystemDefault)
                    {
                        return self.systemDefaults.checkDisplay === CheckPrintingOptions.DisplayButDoNotAllowEdit;
                    }
                    else
                    {
                        return self.user.checkDisplay === CheckPrintingOptions.DisplayButDoNotAllowEdit;
                    }
                }
                return self.localSecurity.RTFReadOnlyDocuments || self.activeDocument.userSecurityAccess !== self.securitySettings.accessLevel.Full;
            },
            defaultLoanId() { return this.orderSummary.defaultLoanID; },
            hasWatermarkStatusOptions() { return !!this.watermarkStatusOptions.length; },
            watermarkOptionSelected() { return _.parseNumber(this.activeDocument.documentWatermarkTextID, 0) != 0; },
            docTemplates() { return _.map(this.settlementStatementDocuments, ssd => SettlementDocTemplateDto.createConfigured(ssd)); },
            defaultLoan() { return _.find(this.loans, l => l.loanID === this.loanId); },
            hasDocTemplates() { return this.docTemplates.length > 0; },
            isSettlementDocument() { return _.some(this.docTemplates, doc => doc.documentTemplateID == this.activeDocument.documentTemplateID) },
            watermarkItems() { return this.isSettlementDocument ? this.watermarkStatusOptions : this.watermarks },
            showStatusDropdown(){ return this.isSettlementDocument ? this.hasWatermarkStatusOptions : this.hasWatermark }
        },
        created () {
            const self = this;
            self.DOC_FILE_TYPES = DocumentFileType;
            self.SAVE_DOC_LOCATIONS = SaveDocumentLocation;
            self.viewModel = self.getDocumentEditorModel(self.orderId, self.localSecurity.SendChecksToFileScan, self.localSecurity.FileScanCheckCategory);
            self.init();
        },
        beforeDestory(){
            if(!this.$rqBusy.isBusy()) return;
            this.$rqBusy.endWait();
        },
        beforeUnmount() {
            // clearing only active prompt documents and saved active prompts as check writing looks at those;
            // this is a safeguard if document viewer is ever used for prompts outside of DocumentGeneration.vue - as DG clears everything on unmount (RQO-31816)
            this.$store.dispatch(DOCUMENT_ACTIONS.CLEAR_STATE, { queue: true, loanId: true, cssMainId: true, invoiceId: true })
        },

        methods: {

            init () {
                const self = this;
                if (!self.viewModel || _.parseNumber(self.viewModel.documents?.length, 0) === 0){
                    self.showNoDocs = true;
                    return;
                }

                self.documents = _.filter(_.cloneDeep(self.viewModel.documents), d => !_.isNil(d));
                self.hasChecks = !_.isEmpty(self.documents) && !_.some(self.documents, ["checkID", null]);
                self.hasDepositSlips = !_.some(self.documents, ["depositSlipID", null]);
                if(self.isFullPageView) {
                    self.hiddenActions = self.documents.length > 1 ? [] : [ HeaderActions.SAVE_ALL.key ];
                    self.$emit("updateHiddenActions", { actions: self.hiddenActions });
                }

                self.navItems = _.map(self.documents, d => new DocNavItemModel({
                    key: d.clientKey,
                    label: _.parseNumber(d.checkID, 0) > 0 && !_.isNil(d.checkNumber)
                        ? `Check_${d.checkNumber}`
                        : _.parseNumber(d.depositSlipID, 0) > 0
                            ? `Deposit_Slip_${d.depositSlipID}`
                            : d.description,
                    status: DocNavItemModel.status.loading,
                    loadResult: ""
                }));

                self.fetchDocumentContent();
            },

            fetchDocumentContent () {
                const self = this;
                if (self.documents.length === 0) return;

                self.loadProgressActive = true;

                let apiRequests = [];

                _.forEach(self.documents, (document, index) => {
                    let tabItem = self.navItems[index];

                    let request = new MergeRequest({
                        ..._.pick(self.viewModel, ["orderId", "cssMainID", "loanID", "invoiceID", "checkID", "depositSlipID", "noSignaturePrint", "sendChecksToFileScan", "fileScanCheckCategory"]),
                        orderDocs: [document.toMergeDocumentModel()],
                        outputType: DocumentFileType.DOCX,
                        returnType: "content"
                    });
                    request.checkID = _.parseNumber(document.checkID, 0);
                    request.isCheckCoverLetter = _.parseBool(document.isCheckCoverLetter, false);
                    request.depositSlipID = _.parseNumber(document.depositSlipID, 0);
                    request.noSignaturePrint = document.noSignaturePrint;

                    let onRequestSuccess = async result => {
                        try {
                            document.fileContent = result[0].content;
                            document.isContentLoaded = true;
                            //document.tagIDs
                            if (self.editorInitialized && index === 0) {
                                self.loadDocument(document);
                            }
                            tabItem.status = ProgressItem.status.success;
                            if (!self.hasChecks) self.updateLastPrintDate(index);
                            if (_.isEmpty(document.documentTags)) {
                                let tags = await self.$api.DocumentTemplatesApi.getTags(document.documentTemplateID);
                                document.tagIDs = _.map(tags, "tagID");
                            }
                        }
                        catch (ex) {
                            document.isContentLoaded = false;
                            document.hasLoadError = true;
                            tabItem.status = ProgressItem.status.failure;
                            tabItem.loadResult = "Document failed to load.  This may occur if the document format is invalid or corrupt";
                            console.error(ex);
                        }
                        return result;
                    };

                    let onRequestFailure = err => {
                        document.isContentLoaded = false;
                        document.hasLoadError = true;
                        tabItem.status = ProgressItem.status.failure;
                        if (err.response) {
                            tabItem.loadResult = `${err.response.status} - ${err.response.statusText}: ${err.response.data.Message}`;
                        }
                        else if (err.errorMessage) {
                            tabItem.loadResult = err.errorMessage;
                        }
                        return err;
                    };

                    let onSSDashboardRequestSuccess = result => {
                        self.watermarkStatuses = _.map(result.hudStatuses, i => new HudStatusDto(i));
                        self.watermarkStatusOptions = _.map(self.watermarkStatuses, i => { return { id: -i.hudStatusID,  name: i.description } });
                    };

                    let onSSDashboardRequestFailure = error => {
                        self.items = [];
                        self.$toast.error({ message: `Error fetching Settlement Statement data.` });
                        return error;
                    }

                    if (tabItem.status !== ProgressItem.status.loading) return true;

                    apiRequests.push(
                        self.$api.DocumentsApi.getMergedDocumentContent(request)
                            .then(onRequestSuccess)
                        .catch(onRequestFailure),

                        self.$api.SettlementDashboardApi.getSSDashboardData(self.defaultLoanId, self.selectedView)
                            .then(onSSDashboardRequestSuccess)
                            .catch(onSSDashboardRequestFailure)
                    );
                });

                return Promise.all(apiRequests);
            },

            getEditedDocuments() {
                const self = this;
                if(!self.hasChecks) return [];
                let checkKeys = _.map(_.filter(self.navItems, i => i?.edited && i?.printed), "key");
                let editedChecks = _.filter(self.documents, d => _.includes(checkKeys, d.clientKey));
                return editedChecks;
            },

            getUnprintedCheckIDs () {
                const self = this;
                if(!self.hasChecks) return [];
                let unprintedKeys = _.map(_.filter(_.clone(self.navItems), ["printed", false]), "key");
                let unprintedDocs = _.filter(self.documents, d => _.includes(unprintedKeys, d.clientKey));
                let checksIDs = _.map(unprintedDocs, 'checkID');
                return checksIDs;
            },

            onLoadProgressIgnore (e) {
                this.loadProgressActive = false;
                if(!this.activeDocument.hasLoadError) return;
                let loadedDocIndex = _.findIndex(this.documents, d => !d.hasLoadError);
                if(loadedDocIndex < 0) return;
                this.currentIndex = loadedDocIndex;
                this.loadDocument(this.documents[loadedDocIndex]);
            },

            onLoadProgressRetry (e) {
                const self = this;
                let failedKeys = [];
                _.forEach(self.navItems, ti => {
                    if (ti.status !== ProgressItem.status.failure) return true;
                    ti.status = TabItem.status.loading;
                    ti.loadResult = "";
                    failedKeys.push(ti.key);
                });
                _.forEach(self.documents, d => {
                    if (!_.includes(failedKeys, d.clientKey)) return true;
                    d.fileContent = "";
                    d.isContentLoaded = false;
                });
                self.fetchDocumentContent();
            },

            onLoadProgressComplete (e) {
                const self = this;
                if (!e.success) return;
                self.loadProgressActive = false;
                if(self.editorInitialized) return;
                self.$rqBusy.startWait();
            },

            onEditorCreated(e) {
                const self = this;
                if (self.documents && self.documents.length > 0 && self.documents[0].isContentLoaded) {
                    self.loadDocument(self.documents[0]);
                }
                self.editorInitialized = true;
            },

            onNavChange (e) {
                const self = this;
                if (e.item.status === ProgressItem.status.failure) {
                    e.cancel();
                    self.loadProgressActive = true;
                    return;
                }
                if (!self.editorInitialized || self.loadingDocuments) {
                    e.cancel();
                    return;
                }
                self.syncToEditor(e.previousIndex)
                    .then(() => {
                        self.loadDocument(self.documents[e.index]);
                    });
            },

            onNavOrderChange (e) {
                const self = this;
                _.forEach(e.items, (item, i) => {
                    let doc = _.find(self.documents, d => d.clientKey === item.key);
                    if (doc) doc.sequence = i + 1;
                });
                self.documents = _.orderBy(self.documents, "sequence");
            },

            onNavCloseItem (e) {
                const self = this;

                self.documents.splice(e.index, 1);

                _.forEach(self.documents, (d, i) => { d.sequence = i + 1; });

                if(e.index === self.currentIndex)
                    self.loadDocument(self.documents[0]);

                if(self.documents.length === 1 && self.hiddenActions.length === 0)
                    self.hiddenActions.push(HeaderActions.SAVE_ALL.key);
            },

            onSaveClick (fileType, saveLocation) {
                this.syncToEditor()
                    .then(() => {
                        this.saveDocuments(fileType, saveLocation);
                    });
            },

            onSaveAllClick(fileType, saveLocation) {
                const self = this;
                self.syncToEditor()
                    .then(() => {
                        self.saveDocuments(fileType, saveLocation, true);
                    });
            },

            onEmailClick(outputFileType) {
                const self = this;
                self.syncToEditor()
                    .then(() => {
                        let emailDoc = new DocumentModel(self.activeDocument);
                        emailDoc.fileType = DocumentFileType.DOCX;
                        self.emailDocuments([emailDoc], outputFileType);
                    });
            },

            onEmailAllClick(outputFileType) {
                const self = this;
                self.syncToEditor()
                    .then(() => {
                        let emailDocs = _.map(self.documents, d => {
                            d.fileType = DocumentFileType.DOCX;
                            return new DocumentModel(d);
                        });
                        self.emailDocuments(emailDocs, outputFileType);
                    });
            },

            onPrintClick (printAll=false) {
                const self = this;
                self.syncToEditor()
                    .then(() => {
                        if (_.parseBool(printAll)) {
                            self.printAll();
                        }
                        else {
                            let navItem = _.find(self.navItems, ["key", self.activeDocument.clientKey]);
                            if (navItem) navItem.printed = true;
                            if(self.activeDocument.applyWatermark)
                                self.pdfViewerInstance.print();
                            else
                                self.print();
                        }
                    });
            },

            print() {
                const self = this;
                let apiPromise = self.$api.UtilitiesApi.docxToPdf(self.activeDocument.fileContent, true);
                self.$rqBusy.wait(apiPromise)
                    .then((result) => {
                        self.pdfViewerPrintAllInstance.load({ fileName: "print.pdf", content: result, printOnLoad: true });
                    })
                    .catch((error) => {
                        console.error(error);
                        self.$toast.error("A error occurred while trying to process this print request.");
                    });
            },

            printAll() {
                const self = this;
                self.syncToEditor()
                    .then(() => {
                        _.each(self.navItems, function(item){item.printed = true;});

                        let saveRequest = self.createSaveRequest();

                        let apiPromise = self.$api.DocumentsApi.processForPrintAll(saveRequest);

                        self.$rqBusy.wait(apiPromise)
                            .then((result) => {
                                self.loadedPdfWatermarkId = -1
                                self.activeDocument.watermarkPreviewVisible = false;
                                self.pdfViewerPrintAllInstance.load({ fileName: "print.pdf", content: result, printOnLoad: true });
                            })
                            .catch((error) => {
                                console.error(error);
                                self.$toast.error("A error occurred while trying to process this print request.");
                            })
                    });
            },

            onPdfViewerPrintAllLoaded() {
                const self = this;

                self.$nextTick()
                    .then(() => {
                        self.pdfViewerPrintAllInstance.print();
                    })
            },

            onPdfViewerPrintAllPrinted() {
                const self = this;
                // Just to see if this ever happens.
            },

            createSaveRequest() {
                const self = this;
                let saveDocuments = _.map(self.documents, d => new DocumentModel(d));
                _.forEach(saveDocuments, (sd, i) => {
                    sd.fileType = DocumentFileType.DOCX;
                    sd.fileContentType = DocumentFileType.DOCX;
                    sd.fileBytes = sd.fileContent;
                    sd.fileContent = null;
                    sd.ordersID = self.viewModel.orderId;
                });

                return new DocumentSaveRequest({
                    orderId: self.viewModel.orderId,
                    outputFileType: self.DOC_FILE_TYPES.PDF_COMBINED,
                    documents: saveDocuments
                });
            },

            onApplyWatermarkChange(val) {
                if(!_.parseBool(val)) {
                    this.activeDocument.watermarkPreviewVisible = false;
                    return;
                }
                this.activeDocument.setDefaultWatermark();
            },

            onSelectedWatermarkChange(e) {
                if(!this.activeDocument.applyWatermark || !this.activeDocument.watermarkPreviewVisible) return;
                this.loadWatermarkPdf();
            },

            onToggleWatermarkPreview(e) {
                const self = this;
                if(self.activeDocument.watermarkPreviewVisible) {
                    self.activeDocument.watermarkPreviewVisible = false;
                }
                else {
                    self.loadWatermarkPdf()
                        .then(() => {
                            self.activeDocument.watermarkPreviewVisible = true;
                        });
                }
            },

            onContentChange(e) {
                const self = this;
                let navItem = _.find(self.navItems, ["key", self.activeDocument.clientKey]);
                if (!navItem) return;
                navItem.edited = true;
            },

            onDocumentLoaded(e) {
                const self = this;
                if(self.editorSyncInProgress) {
                    self.$nextTick(() => self.syncToNextDoc());
                    return;
                }
                if(!self.isPreviewingWatermark || self.activeWatermarkIsLoaded) return;
                self.loadWatermarkPdf();
            },

            loadWatermarkPdf(printOnLoad=false) {
                const self = this;
                let documentTemplateID = self.activeDocument.documentTemplateID;
                let documentWatermarkTextID = self.activeDocument.documentWatermarkTextID;
                let fileName = `${self.activeDocument.description}.docx`;
                let contentPromise = self.documentEditorInstance.getContent()
                    .then(content => self.$api.DocumentsApi.applyWatermark({ documentTemplateID, documentWatermarkTextID, content }))
                    .then(result => {
                        self.pdfViewerInstance.load({ fileName: `${self.activeDocument.name}.pdf`, content: result.content, printOnLoad });
                        self.loadedPdfWatermarkId = documentWatermarkTextID;
                    })
                    .catch(err => {
                        console.error(err);
                        self.$toast.error(`Error retrieving document content: ${err.message}`);
                    });
                return self.$rqBusy.wait(contentPromise);
            },

            loadDocumentContent (mergeResults, clientKey = 0) {
                const self = this;
                if (!mergeResults || mergeResults.length === 0) return;

                let mergeResultList = _.map(mergeResults, r => new MergeResult(r));
                if (clientKey === 0) {
                    _.forEach(self.documents, doc => {
                        let mergeResult = _.find(mergeResultList, mr => mr.orderDocumentID === doc.orderDocumentID);
                        if(!mergeResult) return;
                        doc.fileContent = mergeResult.content;
                        doc.isContentLoaded = true;
                    });

                    if (self.editorInitialized) {
                        self.loadDocument(self.documents[0]);
                    }
                } else {
                    let mergedDoc = _.find(self.documents, d => d.clientKey === clientKey);
                    let mergedDocIndex = _.findIndex(self.documents, d => d.clientKey === clientKey);

                    mergedDoc.fileContent = mergeResultList[0].content;
                    mergedDoc.isContentLoaded = true;

                    if (self.editorInitialized && mergedDocIndex === 0) {
                        self.loadDocument(mergedDoc);
                    }
                }
            },

            loadDocument (doc) {
                const self = this;
                if(!self.documentEditorInstance || !doc?.isContentLoaded) return;
                let docName = doc?.name || doc?.description;
                self.documentEditorInstance.load(docName, DocumentFileType.DOCX, doc?.fileContent);
            },

            saveDocuments(fileType, saveLocation, saveAll=false){
                const self = this;
                let documentDescription = self.documentDescription;
                self.saveDocumentViewModel = new SaveDocumentViewModel({ ...self.viewModel, saveAll, fileType, saveLocation, documentDescription });
                self.openSaveDialog();
            },

            saveEditedChecks(){
                const self = this;

                if(!self.hasChecks && !self.hasDepositSlips) return Promise.resolve(null);

                let editedDocuments = self.getEditedDocuments();
                if (_.isEmpty(editedDocuments) || !self.localSecurity.SendChecksToFileScan) return Promise.resolve(null);

                _.forEach(editedDocuments, (sd, i) => {
                    sd.fileType = DocumentFileType.DOCX;
                    sd.fileContentType = DocumentFileType.DOCX;
                    sd.ordersID = self.orderId;
                    sd.description = sd.fileScanDescription = sd.name = `Check_${sd.checkNumber}`;
                    sd.documentWatermarkTextID = 0;//Hardcoded to 0, so I can use a custom watermark on the backend
                });

                return self.$api.FileScanApi.saveChecksToFileScan(new FileScanDocumentSaveRequest({
                    ordersID: self.viewModel.orderId,
                    fileType: DocumentFileType.PDF,
                    fileScanDocumentDuplicateAction: FileScanDocumentAction.ReplaceExisting,
                    fileScanCategoryID: self.viewModel.fileScanCheckCategory,
                    fileScanDescription: null,
                    fileScanDescriptionID: null,
                    tagIDs: [],
                    documents: editedDocuments
                }));
            },

            emailDocuments(documents, outputFileType=DocumentFileType.PDF) {
                const self = this;

                if(outputFileType != DocumentFileType.PDF && self.localSecurity.RestrictSendToOptionsToPDFEntry){
                    return;
                }

                let request = new DocumentSaveRequest({ orderId: self.viewModel.orderId, outputFileType, documents }, true);
                let apiPromise = self.$api.DocumentsApi.convertDocuments(request);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        if (result && result.documents && result.documents.length > 0) {
                            let resultDocuments = _.map(result.documents, d => new DocumentModel(d));
                            let attachments = _.map(resultDocuments, d => ({ name: d.localFileName, type: "BASE64", contentBase64: d.fileContent }));
                            self.$dialog.open({
                                title: "Email Documents",
                                width: "90%",
                                height: "90%",
                                scrollable: false,
                                okTitle: "Send",
                                component: EmailForm,
                                props: { attachments },
                                onOk(e) { return e.component.onOk(); }
                            });
                        } else {
                            self.$toast.error("Document preparation for email attachments failed.");
                        }
                    })
                    .catch(err => {
                        self.$toast.error("Document preparation for email attachments failed.");
                        console.error(err);
                    });
            },

            updateLastPrintDate(index) {
                const self = this;
                return self.$api.DocumentsApi.updateLastPrintDate(self.documents[index].orderDocumentID)
                    .then(result => {
                        if (!result) return;
                        self.documents[index].lastPrint = result;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            },

            hideToolbarItems() {
                const self = this;
                let hiddenItemIds = _.map(["new", "open", "restrict_edit"], idPart => `${self.editorComponentId}_toolbar_${idPart}`);
                let toolbarInstance = _.get(self, "ej2Instances.toolbarModule.toolbar", null);
                if(toolbarInstance) {
                    let toolbarItems = toolbarInstance.items || [];
                    _.each(toolbarItems, (item, index) => {
                        if(!_.includes(hiddenItemIds, item.id)) return;
                        toolbarInstance.hideItem(index, true);
                    });
                }
            },

            openSaveDialog() {
                const self = this;
                let isTargetingFileScan = self.saveDocumentViewModel.saveLocation === SaveDocumentViewModel.SaveLocation.FileScan;
                let documents = self.saveDocumentViewModel.saveAll ? self.documents : [self.documents[self.currentIndex]];
                self.saveDocumentViewModel.tagIDs = documents[0].tagIDs;
                self.$dialog.open({
                    title: self.saveDocumentViewModel.saveAll ? "Save All Documents" : "Save Document",
                    component: SaveDocumentsDialog,
                    width: isTargetingFileScan ? 950 : 600,
                    minHeight: 200,
                    adaptive: true,
                    hideFooter: !isTargetingFileScan,
                    props: {
                        viewModel: self.saveDocumentViewModel,
                        documents,
                    },
                    onOk(e) {
                        if (e.component.saveComplete) return true;
                        e.component.save();
                        e.component.saveComplete = true;
                        return false;
                    },
                    onComponentAction(e) {
                        return false;
                        // if (e.originalEvent.name !== "save-docx" || !e.originalEvent.data) return false;
                        // self.documentEditorInstance.save(e.originalEvent.data.fileName);
                        // return true;
                    }
                });
            },

            syncToEditor (documentIndex) {
                const self = this;
                let index = _.isNil(documentIndex) ? self.currentIndex : documentIndex;
                return self.documentEditorInstance.getContent()
                    .then(result => {
                        self.documents[index].fileContent = result;
                        self.documents[index].syncedWithEditor = true;
                    });
            },

            handleError (err) {
                console.error(err);
            },

            dispose() { return Promise.resolve(true); }
        }

    };
</script>