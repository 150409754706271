import { UserSecuritySettings, ProgressItem, TabItem, StandardLanguageDto } from "../../shared/models/models";
import {
    OrderPrintDocumentSourceType, OrderPrintDocumentType, PromptSaveOption, PromptLevelType,
    DocumentFileType, SaveDocumentAction, FileScanDocumentType, OperatorType
} from "./enums";
import { VariableType } from "./components/editor-dialogs/models";
import { ContentDataFormat, NumberFormat, TextControlListType, ListFormatCharacter } from "../../shared/models/enums";
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

export class DocumentGridModel {
    constructor(options, parentKey=null) {
        this.data = options || {};
        this.clientKey = this.data.clientKey || _.uniqueId();
        this.parentKey = parentKey;
        this.ordersID = _.parseNumber(this.data.ordersID, null);
        this.isRequired = this.data.isRequired || false;
        this.isPackage = _.parseBool(this.data.isPackage);
        this.documentPackageID = this.isPackage ? _.parseNumber(this.data.documentPackageID || this.data.id, 0) : 0;
        this.documentTemplateID = this.isPackage ? 0 :  _.parseNumber(this.data.documentTemplateID || this.data.id, 0);
        this.orderDocumentID = _.parseNumber(this.data.orderDocumentID, null);

        this.description = this.data.description ||  null;
        this.type = this.data.documentType || null;
        this.lastPrint = this.data.lastPrint || null;

        this.usStatesID = _.parseNumber(this.data.usStatesID, null);
        this.state = this.data.usStateName || null;
        this.category = this.data.documentCategoryName || null;
        this.documentCategoryID = this.data.documentCategoryID || null;
        this.fileScanCategoryID = this.data.fileScanCategoryID || null;

        this.documentStates = this.data.documentStates || null;
        this.documentCategories = this.data.documentCategories || null;

        this.hasWatermark = _.parseBool(this.data.hasWatermark, false);
        this.watermarks = this.data.watermarks || [];
        this.userSecurityAccess = _.parseNumber(this.data.userSecurityAccess, 2);

        let pkgDocs = this.data.packageItems || [];
        this.packageDocuments = _.map(pkgDocs, d => new DocumentGridModel(d, this.clientKey));

        this.packageItemSequence = _.parseNumber(this.data.packageItemSequence, 0);
    }

    get isOrderItem() {
        return this.isPackage
            ? _.some(this.packageDocuments, d => d.isOrderItem)
            : _.gt(_.parseNumber(this.orderDocumentID, 0), 0);
    }
}

export class DocumentTemplateDto {
    constructor(options, userRegions=null, globalRegionId=null) {
        options = options || {};
        this.clientKey = options.clientKey || _.uniqueId();
        this.name = options.name || null;
        this.description = options.description || null;
        this.documentTemplateID = _.parseNumber(options.documentTemplateID || options.id, 0);
        this.regionID = _.parseNumber(options.regionID, null);
        this.regionDisplay = options.regionDisplay || null;
        this.defaultUserSecurityAccess = _.parseNumber(options.defaultUserSecurityAccess, null);
        this.defaultPCloserSecurityAccess = _.parseNumber(options.defaultPCloserSecurityAccess, null);
        this.documentSource = _.parseNumber(options.documentSource, 1);
        this.documentType = _.parseNumber(options.documentType, 0);
        this.isHidden = options.isHidden || false;
        this.userSecurityStatus = _.parseNumber(options.userSecurityStatus, 0);
        this.pcCloserSecurityStatus = _.parseNumber(options.pcCloserSecurityStatus, 0);
        this.dateCreated = options.dateCreated || null;
        this.dateModified = options.dateModified || null;
        this.lastModifiedBy = options.lastModifiedBy || null;
        this.regID = _.parseNumber(options.regID, null);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.documentContent = options.documentContent || null;
        this.documentContentOpenXml = options.documentContentOpenXml || null;
        this.hasWatermark = _.parseBool(options.hasWatermark, false);
        this.watermarks = options.watermarks || [];
        this.fileId = options.fileId || null;
        this.webDavProxySubDir = options.webDavProxySubDir || null;
        this.lastPrintedDate = options.lastPrintedDate || null;
        this.readOnly = _.isNil(this.regionID) || _.isEmpty(userRegions) || _.isNil(globalRegionId)
            ? _.parseBool(options.readOnly)
            : _.every(userRegions, r => r.regionID !== this.regionID && r.regionID !== globalRegionId);
        this.packageItemSequence = _.parseNumber(options.packageItemSequence, 0);
        this.documentTags = options.documentTags || [];
        this.documentTagDisplay = options.documentTagDisplay || null;
    }

    getDefaultContent() {
        switch(this.defaultFileType) {
            case DocumentFileType.DOCX: return this.documentContentOpenXml;
            case DocumentFileType.RTF: return btoa(this.documentContent);
        }
        return this.documentContent;
    }
    get defaultFileType() { return _.isNil(this.documentContentOpenXml) ? DocumentFileType.RTF : DocumentFileType.DOCX; }

    toDataObject() { return _.pickBy(this, (v,k) => !_.includes(["clientKey"], k)); }
}

export class DocumentModel extends DocumentTemplateDto {
    constructor(options) {
        super(options);
        //this.clientKey = options.clientKey || _.uniqueId();  //exists in DocumentTemplateDto
        this.ordersID = options.ordersID || null;
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || null;
        this.orderWorkflowTaskDocumentID = options.orderWorkflowTaskDocumentID || null;

        this.documentCategoryID = options.documentCategoryID || null;
        this.documentCategoryName = options.documentCategoryName || null;
        this.usStatesID = options.usStatesID || null;
        this.usStateName = options.usStateName || null;

        this.documentPackageID = options.documentPackageID || 0;
        this.documentPackageDescription = options.documentPackageDescription || null;

        this.checkID = _.parseNumber(options.checkID, null);
        this.checkNumber = _.parseNumber(options.checkNumber, null);
        this.noSignaturePrint = _.parseBool(options.noSignaturePrint, null);
        this.depositSlipID = _.parseNumber(options.depositSlipID, null);

        this.orderDocumentID = _.parseNumber(options.orderDocumentID, null);
        this.lastPrint = options.lastPrint || null;
        this.isPrintList = options.isPrintList || false;
        this.isCheckCoverLetter = _.parseBool(options.isCheckCoverLetter, false);

        const promptList = options.prompts || [];
        this.prompts = _.map(promptList, p => new DocumentPromptInfo(p));

        this.fileContentType = options.fileContentType || DocumentFileType.DOCX;
        this.fileContent = options.fileContent || null;
        this.isBase64Encoded = _.parseBool(options.isBase64Encoded);
        this.fileBytes = options.fileBytes || null;
        this.docContent = options.docContent || null;
        this.content = null;
        this.isContentLoaded = false;
        this.syncedWithEditor = false;
        this.hasLoadError = false;
        this.isValid = true;

        this.sequence = options.sequence || 999;

        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, null);
        this.fileScanPageID = options.fileScanPageID || null;
        this.standardDescription = options.standardDescription || "";
        this.fileScanDescription = options.fileScanDescription || this.description || "";
        this.fileType = options.fileType || null;
        this.fileScanDocumentDuplicateAction = _.parseNumber(options.fileScanDocumentDuplicateAction, 0);
        this.tagIDs = options.tagIDs || [];
        this.publish = options.publish || false;

        this.allowAnonymous = options.allowAnonymous || false;
        this.roleAccessList = options.roleAccessList || [];

        this.publishToPaperlessCloser = options.publishToPaperlessCloser || false;
        this.publishedDate = options.publishedDate || null;

        this.saveAsFileName = options.saveAsFileName || null;
        this.overwriteExisting = options.overwriteExisting || false;
        this.originalDescription = options.description || null;
        this.requiresAttention = _.parseBool(options.requiresAttention, false);

        //this.documentUrl = options.documentUrl || "";

        //this.availableDescriptions = options.availableDescriptions || [];

        this.documentWatermarkTextID = _.parseNumber(options.documentWatermarkTextID, null);
        this.wopiFileName = options.wopiFileName || "";
        this.userSecurityAccess = _.parseNumber(options.userSecurityAccess, 2);

        /* Client model properties */
        this.watermarkPreviewVisible = false;
        this.isReady = false;
        this.wopiDocumentUrl = "";
        /***************************/

        //#region Members not currently used in this context client-side
        this.devicePath = options.devicePath || null;
        this.volumeSubdirectory = options.property || null;
        this.fileScanDeviceID = options.fileScanDeviceID || null;
        this.fileScanVolumeID = options.fileScanVolumeID || null;
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, 0);
        this.useDefaultDescription = options.useDefaultDescription || false;
        this.numberOfPages = options.numberOfPages || 1;
        this.hudMainID = options.hudMainID || null;
        this.cssMainID = options.cssMainID || null;
        this.invoiceID = options.invoiceID || null;
        this.cdfMainID = options.cdfMainID || null;
        this.publishedBy = options.publishedBy || null;
        this.publishedByUserID = options.publishedByUserID || null;
        this.publicID = options.publicID || null;
        this.loanID = options.loanID || null;
        this.publishedByContactID = options.publishedByContactID || null;
        this.publishedByPaperlessCloserAccessID = options.publishedByPaperlessCloserAccessID || null;
        //#endregion
    }

    get documentSourceName() { return OrderPrintDocumentSourceType.displayValue(this.documentSource); }
    get documentTypeName() { return OrderPrintDocumentType.displayValue(this.documentType); }

    get localFileName() { return `${this.name || this.description}${DocumentFileType.fileExtension(this.fileType)}`; }
    get officeFileName() { return `doc_${this.documentTemplateID}_${this.ordersID}${DocumentFileType.fileExtension(this.fileType)}`; }

    get existsAction() { return _.parseNumber(this.fileScanDocumentDuplicateAction); }
    set existsAction(value) {
        this.fileScanDocumentDuplicateAction = Number(value);
        this.overwriteExisting = Number(value) === SaveDocumentAction.Replace;
        if (this.fileScanDocumentDuplicateAction === SaveDocumentAction.Rename) {
            this.description = `${this.originalDescription}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
        }
        else {
            this.description = this.originalDescription;
        }
    }

    get saveAsFileType() { return this.fileType; }
    set saveAsFileType(value) { this.fileType = _.parseNumber(value); }

    get publish() { return this.publishToPaperlessCloser; }
    set publish(value) {
        this.publishToPaperlessCloser = value && value.toString() === "true";
        if (this.publishToPaperlessCloser)
            this.publishedDate = DateTimeHelper.nowTenant();
        else
            this.publishedDate = null;
    }

    get documentTypeDescription() { return FileScanDocumentType.displayValue(this.documentType); }

    get applyWatermark() { return _.parseNumber(this.documentWatermarkTextID, 0) !==0; }
    set applyWatermark(val) {
        if(_.parseBool(val) && this.hasWatermark) return;
        this.documentWatermarkTextID = null;
    }

    setDefaultWatermark() {
        if(!this.hasWatermark) return;
        _.forEach(this.watermarks, w => {
            if(!w.isDefault) return;
            this.documentWatermarkTextID = w.documentWatermarkTextID;
            return true;
        });
        if(!_.isNil(this.documentWatermarkTextID)) return;
        this.documentWatermarkTextID = _.getNumber(this, 'watermarks[0].documentWatermarkTextID', null);
    }

    getContent(dataFormat) {
        return _.parseContentFormat(this.fileContent, dataFormat);
    }

    toMergeDocumentModel() {
        const promptValues = {};
        let promptValueDetails = [];
        _.forEach(this.prompts, p => {
            let promptIdentifier = _.startsWith(p.prompt.promptName, "@")
                ? p.prompt.promptName
                : `@${p.prompt.promptName}:`;
            if (p.promptLevelType > 0)
                promptIdentifier += this.description;
            promptValues[promptIdentifier] = p.prompt.promptValue;

            let standardLanguageIds = [];
            _.forEach(p.prompt.standardLanguageList, i => {
                standardLanguageIds.push(i.standardLanguageID);
            });
            promptValueDetails.push(
                {
                    key: promptIdentifier,
                    value: p.prompt.promptValue,
                    standardLanguageIds: standardLanguageIds
                }
            );
        });
        return new MergeDocumentModel({
            orderDocumentId: this.orderDocumentID,
            promptValues: promptValues,
            promptValueDetails: promptValueDetails
        });
    }
}

/**
 prompt data types:
    string  =   0
    money   =   1
    date    =   2
    memo    =   3
    yes/no  =   4
    select  =   5
 */

export class PromptDataType {
    static get String () { return  0; }
    static get Money () { return 1; }
    static get Date () { return 2; }
    static get Memo () { return 3; }
    static get YesNo () { return 4; }
    static get Select () { return 5; }
    static get StandardLanguage () { return 6; }
}

export class DocumentPromptGroup {
    constructor(options, mapPromptInfo=true) {
        options = options || {};
        this.orderId = options.orderId || 0;
        this.orderDocumentId = options.orderDocumentId || 0;
        this.documentName = options.documentName || "";
        this.description = options.description || "";
        this.groupTitle = options.groupTitle || this.description || this.documentName || "Global";
        this.isGlobal = _.isBoolean(options.isGlobal) ? options.isGlobal : false;
        this.regId = options.regId || 0;

        this.isValid = true;

        let promptList = options.prompts || [];
        this.prompts = mapPromptInfo ? _.map(promptList, p => {
            const np = new DocumentPromptInfo(p);
            if (!np.ordersID && this.orderId > 0) np.ordersID = this.orderId;
            if(!np.orderDocumentID && this.orderDocumentId > 0) np.orderDocumentID = this.orderDocumentId;
            return np;
        }) : promptList;
    }

    get hasPrompts() { return !_.isEmpty(this.prompts); }

    get hasDirtyPrompts() {
        return _.some(this.prompts, p => p.isDirty);
    }

    get globalPromptsOnly() {
        return _.every(this.prompts, p => p.promptLevelType === 0);
    }

    get hasStandardLanguagePrompts() {
        return _.some(this.prompts, p => p.promptDataType === 6);
    }

    setOrderId(id) {
        this.orderId = id;
        _.forEach(this.prompts, p => p.ordersID = id);
    }

    validatePrompts(excludeGlobal=false) {
        const invalidPrompts = [];
        _.forEach(this.prompts, p => {
            if(excludeGlobal && p.promptLevelType === 0) return;
            if (!p.validate()) invalidPrompts.push(p);
        });
        this.isValid = invalidPrompts.length > 0;
        return invalidPrompts;
    }
}
/**
 * SaveAlways = 0
 * SaveChanged = 1
 * DontSave = 2
 * SaveBlanks = 3
 * SaveDefaults = 4
 */
export class DocumentPrompt {
    constructor(options) {
        options = options || {};
        this.clientKey = options.clientKey || _.uniqueId("prompt_");
        this.orderPrintPromptValueID = options.orderPrintPromptValueID || 0;
        this.ordersID = _.parseNumber(options.ordersID, null);
        this.orderDocumentID = options.orderDocumentID || null;
        this.statementType = options.statementType || "";
        this.promptName = options.promptName || "";
        this.promptText = options.promptText || "";
        this.promptLevelType = options.promptLevelType !== 0 && options.promptLevelType !== "0"
            ? 1
            : Number(options.promptLevelType);
        this.promptDataType = _.parseNumber(options.promptDataType, 0);
        this.scopeDocumentDescription = options.scopeDocumentDescription || "";
        this.usersID = _.parseNumber(options.usersID, 0);

        this.defaultValue = options.default || "";
        this.defaultLineCount = options.defaultLineCount || "1";

        // SL the default value is used for category filter behavior (RQ One) and so promptValue actually associates to defaultLineCount
        this.promptValue = this.promptDataType === PromptDataType.StandardLanguage ? options.promptValue || this.defaultLineCount : options.promptValue || this.defaultValue;
        this.originalValue = options.isDirty ? this.originalValue : this.promptValue

        this.dropDownValues = options.dropDownValues || "";

        const standLangs = options.standardLanguageList || [];
        this.standardLanguageList = _.map(standLangs, sl => {
            sl.ordersID = this.ordersID;
            sl.orderDocumentID = this.orderDocumentID;
            sl.orderDocumentDescription = this.scopeDocumentDescription;
            sl.workflowTaskID = sl.workflowTaskID ?? 0;
            return new StandardLanguageModel(sl);
        });
        this.originalStandardLanguageListIds = this.standardLanguageList.map(s => s.standardLanguageID)
        this.overrideDefaultLineCount = _.parseBool(options.overrideDefaultLineCount);

        this.saveOptions = _.parseNumber(options.saveOptions, 0);
        this.hasFormatting = _.parseBool(options.hasFormatting);
        this.format = _.parseNumber(options.format, 0);

        this.isValid = true;
        this.isRequired = _.parseBool(options.isRequired);
    }

    get dropDownItems() {
        if(_.isEmpty(this.dropDownValues)) return [];
        return _.split(this.dropDownValues, "|");
    }

    get isDirty() { return this.promptValue !== this.originalValue || (this.promptDataType == PromptDataType.StandardLanguage && !_.isEqual(this.originalStandardLanguageListIds, this.standardLanguageList.map(s => s.standardLanguageID))); }

    get isGlobal() { return this.promptLevelType === PromptLevelType.Global; }

    get requiresSave() {
        if (this.saveOptions === PromptSaveOption.DontSave)
            return false;

        if (this.saveOptions === PromptSaveOption.SaveChanged)
            return this.isDirty;

        if (this.saveOptions === PromptSaveOption.SaveDefaults)
            return this.promptValue.length > 0;

        //"Save Always" (0) and "Save Including Blanks" (3) will always logically have the same outcome
        return true;
    }

    get standardLanguagePrompts() {
        return _.flatMap(this.standardLanguageList, sl => sl.prompts);
    }

    setOrderId(id) {
        this.ordersID = id;
        if (this.standardLanguageList.length === 0) return;
        this.standardLanguageList = _.map(this.standardLanguageList, sl => {
            let listModel = new StandardLanguageModel(sl);
            listModel.setOrderId(id);
            return listModel;
        });
    }

    validate(validateStandardLanguages=false) {
        let standardLanguagesValid = true;
        if(!_.isEmpty(this.standardLanguageList)) {
            _.forEach(this.standardLanguageList, sl => {
                standardLanguagesValid = sl.validate() && standardLanguagesValid;
            });
            this.isValid = standardLanguagesValid;
            return standardLanguagesValid;
        }
        this.isValid = this.isRequired
            ? !_.isNullOrEmpty(this.promptValue)
            : true;
        return this.isValid;
    }

    clone() {
        return _.cloneDeep(this);
    }

    toDataObject() {
        return _.pick(this, ["orderPrintPromptValueID","ordersID","promptName","promptText","promptValue","promptLevelType","promptDataType","dropDownValues","scopeDocumentDescription","usersID"]);
    }
}

export class DocumentPromptInfo {
    constructor(options) {
        options = options || {};
        this.clientKey = options.clientKey || _.uniqueId("prompt_info_");

        const p = options.prompt || {};
        this.prompt = new DocumentPrompt(p);

        const conds = _.isArray(options.conditions) ? options.conditions : [];
        this.conditions = _.map(conds, c => new DocumentPromptCondition(c));
    }

    get usersID() { return this.prompt.usersID; }
    set usersID(val) { this.prompt.usersID = val; }

    get ordersID() { return this.prompt.ordersID; }
    set ordersID(val) { this.prompt.setOrderId(val); }

    get orderDocumentID() { return this.prompt.orderDocumentID; }
    set orderDocumentID(val) { this.prompt.orderDocumentID = val; }

    get orderPrintPromptValueID() { return this.prompt.orderPrintPromptValueID; }
    set orderPrintPromptValueID(val) { this.prompt.orderPrintPromptValueID = val; }

    get promptName() { return this.prompt.promptName; }
    set promptName(val) { this.prompt.promptName = val; }

    get promptText() { return this.prompt.promptText; }
    set promptText(val) { this.prompt.promptText = val; }

    get promptValue() { return this.prompt.promptValue; }
    set promptValue(val) { this.prompt.promptValue = val; }

    get scopeDocumentDescription() { return this.prompt.scopeDocumentDescription; }
    set scopeDocumentDescription(val) { this.prompt.scopeDocumentDescription = val; }

    get promptLevelType() { return this.prompt.promptLevelType; }
    set promptLevelType(val) { this.prompt.promptLevelType = val; }

    get promptDataType() { return this.prompt.promptDataType; }
    set promptDataType(val) { this.prompt.promptDataType = val; }

    get isValid() { return this.prompt.isValid; }
    get isDirty() { return this.prompt.isDirty; }
    get isGlobal() { return this.prompt.isGlobal; }

    get hasConditions() { return !_.isEmpty(this.conditions); }
    get isVisible() { return !this.hasConditions || _.every(this.conditions, "canShowPrompt")}

    updateAndEvaluateConditions(promptName, value) {
        _.each(this.conditions, c => {
            if(!c.tryUpdatePromptValue(promptName, value)) return;
            c.evaluate();
        });
    }

    get isRequired() { return this.prompt.isRequired; }
    validate() { return !this.isVisible ? true : this.prompt.validate(); }
    toDataObject() { return this.prompt.toDataObject(); }
}

export class DocumentPromptCondition {
    constructor(options) {
        options = options || {};
        let cond = options.conditional || {};
        this.negate = _.parseBool(options.negate);
        this.statementType = _.parseNumber(cond.statementType, null);
        this.leftVariableType = _.parseNumber(cond.leftVariableType, 0);
        this.leftVariableValue = cond.leftVariableValue || null;
        this.operator = _.parseNumber(cond.operator, 0);
        this.rightVariableType = _.parseNumber(cond.rightVariableType, 0);
        this.rightVariableValue = cond.rightVariableValue || null;
        this.statementDefinition = cond.statementDefinition || null;
        this.result = cond.result;
        this.hasFormatting = _.parseBool(cond.hasFormatting);

        this.leftPromptName = "";
        this.rightPromptName = "";
        this.setPromptNames();

        this.leftExprValue = this.leftVariableType === VariableType.Constant ? this.leftVariableValue : null;
        this.rightExprValue = this.rightVariableType === VariableType.Constant ? this.rightVariableValue : null;

        this.evalReady = !this.leftPromptName && !this.rightPromptName;
        this.evaluated = options.evaluated || false;
        this.conditionMet = options.conditionMet || false;
    }

    get isPrompt() { return !_.isEmpty(this.dependentPromptName); }

    get canShowPrompt() { return this.evaluated && this.conditionMet; }

    setPromptNames() {
        const setPromptName = varSide => {
            let varType = _.getNumber(this, `${varSide}VariableType`, 0);
            let varValue = _.get(this, `${varSide}VariableValue`, null);

            if(varType !== VariableType.Prompt || _.isEmpty(varValue)) return "";

            let si = _.indexOf(varValue, "@") + 1;
            let ei = _.indexOf(varValue, "~");
            let pName = ei > 0
                ? varValue.substring(si,ei)
                : varValue.substring(si);

            _.set(this, `${varSide}PromptName`, pName);
        };

        setPromptName("left");
        setPromptName("right");
    }

    tryUpdatePromptValue(name, value) {
        const setPromptValue = varSide => {
            let promptName = _.get(this, `${varSide}PromptName`, null);
            if(promptName !== name) return false;
            _.set(this, `${varSide}ExprValue`, value);
            return true;
        };
        let success = setPromptValue("left");
        if(!success) success = setPromptValue("right");
        if(success) this.evalReady = true;
        return success;
    }

    evaluate() {
        if(!this.evalReady) return false;
        this.evaluated = true;
        let evalResult = null;

        // Mergefield conditionals come back as true/false as a whole under .Result so look at the result
        if(this.leftVariableType === VariableType.MergeField) {
            this.conditionMet = this.negate ? !this.result : this.result;
            return this.conditionMet;
        }

        //Convert null values to empty strings for string comparisons
        if (typeof this.rightExprValue === "string" && _.isNil(this.leftExprValue)) { this.leftExprValue = ""; }
        if (typeof this.leftExprValue === "string" && _.isNil(this.rightExprValue)) { this.rightExprValue = ""; }

        switch(this.operator) {
            case OperatorType.Equals:
                evalResult = this.leftExprValue == this.rightExprValue;
                break;
            case OperatorType.NotEquals:
                evalResult = this.leftExprValue != this.rightExprValue;
                break;
            case OperatorType.GreaterThan:
                evalResult = this.evaluatePotentialNumber(this.leftExprValue, this.rightExprValue, (a,b) => a > b);
                break;
            case OperatorType.GreaterThanOrEquals:
                evalResult = this.evaluatePotentialNumber(this.leftExprValue, this.rightExprValue, (a,b) => a >= b);
                break;
            case OperatorType.LessThan:
                evalResult = this.evaluatePotentialNumber(this.leftExprValue, this.rightExprValue, (a,b) => a < b);
                break;
            case OperatorType.LessThanOrEquals:
                evalResult = this.evaluatePotentialNumber(this.leftExprValue, this.rightExprValue, (a,b) => a <= b);
                break;
            case OperatorType.In:
                evalResult = _.includes(_.split(this.rightExprValue, ";"), this.leftExprValue);
                break;
        }
        this.conditionMet = this.negate ? !evalResult : evalResult;
        return this.conditionMet;
    }

    evaluatePotentialNumber(leftExprValue, rightExprValue, compareFn) {
        const leftNumber = _.parseNumber(leftExprValue);
        const rightNumber = _.parseNumber(rightExprValue);

        // if either are not a number, compare as it was before to not change any previous file behavior
        if(_.isNaN(leftNumber) || _.isNaN(rightNumber))
            return compareFn(leftExprValue, rightExprValue);

        return compareFn(leftNumber, rightNumber);
    }
}

export class StandardLanguageModel extends StandardLanguageDto {
    constructor(options) {
        options = options || {};

        super(options);

        this.clientKey = options.clientKey || _.uniqueId("standard_language_");
        this.ordersID = options.ordersID || 0;
        this.orderDocumentID = options.orderDocumentID || 0;
        this.orderDocumentDescription = options.orderDocumentDescription || null;
        this.standardLanguageCategoryDescription = options.standardLanguageCategoryDescription || null;
        this.standardLanguagePackageCategoryID = options.standardLanguagePackageCategoryID || null;
        this.standardLanguagePackageCategoryDescription = options.standardLanguagePackageCategoryDescription || null;
        this.standardLanguagePackageDescription = options.standardLanguagePackageDescription || null;

        const promptList = options.prompts || [];
        this.prompts = _.map(promptList, p => new DocumentPromptInfo(p));

        this.promptRequestFailed = _.parseBool(options.promptRequestFailed);
        this.promptRequestError = options.promptRequestError || null;
    }

    setOrderId(id) {
        this.ordersID = id;
        _.forEach(this.prompts, p => {
            if(_.isFunction(p.setOrderId))
                p.setOrderId(id);
            else if(_.isFunction(_.get(p, "prompt.setOrderId", null)))
                p.prompt.setOrderId(id);
        });
    }

    get promptValues() {
        let result = {};
        _.forEach(this.prompts, p => { result[p.promptName] = p.promptValue; });
        return result;
    }

    validate() {
        let isValid = true;
        _.forEach(this.prompts, p => {
            isValid = p.validate() && isValid;
        });
        return isValid;
    }

    toDataObject() {
        let result = _.toPlainObject(this);
        result.promptValues = _.mapKeys(this.promptValues, (v,k) => _.startsWith(k, "@") ? k : `@${k}:`);
        return result;
    }
}

export class StandardLanguageHeaderModel extends StandardLanguageModel {
    constructor(options) {
        super(options);

        this.data = options || {};
        this.id = this.data.id || 0;
        this.isPackage = this.data.isPackage;
        this.packageItems = []

        if(options.isPackage){
            this.packageItems = _.map(options.packageItems, item => {return new StandardLanguageHeaderModel(item)});
        }
    }
}

export class StandardLanguagePackageModel {
    constructor(options) {
        options = options || {};
        this.clientKey = options.clientKey || 0;
        this.standardLanguagePackageID = options.standardLanguagePackageID || 0;
        this.description = options.description || null;
        this.standardLanguageCategoryID = options.standardLanguageCategoryID || -1;
        this.standardLanguageCategoryDescription = options.standardLanguageCategoryDescription || null;
        this.packageItems = options.packageItems || [];
    }

    static fromPackageItems(items){
        if(!items || !items.length) return null;
        return new StandardLanguagePackageModel({
            standardLanguagePackageID: items[0].standardLanguagePackageID,
            description: items[0].standardLanguagePackageDescription,
            standardLanguageCategoryID: items[0].standardLanguageCategoryID,
            standardLanguageCategoryDescription: items[0].standardLanguageCategoryDescription,
            packageItems: items,
        });
    }
}

export class DocumentUserPermissions {
    constructor(options) {
        options = options || {};
        this.AllowEditPromptsFilledByOthers = _.parseBool(options.AllowEditPromptsFilledByOthers, true);

        let publishToPC = _.parseBool(options.AllowDocumentPublishToPaperlessCloser)
            || _.parseBool(options.AllowPaperlessCloserPublishDocuments);
        this.AllowDocumentPublishToPaperlessCloser = publishToPC;
        this.AllowPaperlessCloserPublishDocuments = publishToPC;

        this.CanReplaceDocuments = _.parseBool(options.CanReplaceDocuments, false);
        this.FileScanCanCreateNewOrders = _.parseBool(options.FileScanCanCreateNewOrders, true);
        this.FileScanCanImportFilesAndDocuments = _.parseBool(options.FileScanCanImportFilesAndDocuments, true);
        this.FileScanCanAttachFilesAndDocuments = _.parseBool(options.FileScanCanAttachFilesAndDocuments, true);
        this.FileScanAllowEditMode = _.parseBool(options.FileScanAllowEditMode, true);
        this.FileScanAllowEditDocuments = _.parseBool(options.FileScanAllowEditDocuments, true);
        this.FileScanAllowFileManagement = _.parseBool(options.FileScanAllowFileManagement, true);
        this.RTFReadOnlyDocuments = _.parseBool(options.RTFReadOnlyDocuments, true);
        this.FileScanCanDeleteDocuments = _.parseBool(options.FileScanCanDeleteDocuments, true);
        this.FileScanAllowCategoryManagement = _.parseBool(options.FileScanAllowCategoryManagement, true);
        this.PredefinedDescriptions = _.parseBool(options.PredefinedDescriptions, true);
        this.FileScanAllowDescriptionManagement = _.parseBool(options.FileScanAllowDescriptionManagement, true);
        this.RestrictSendToOptionsToPDFEntry = _.parseBool(options.RestrictSendToOptionsToPDFEntry, true);
        this.FileScanAllowBurnToCD = _.parseBool(options.FileScanAllowBurnToCD, true);
        this.RTFReadOnlyDocuments = _.parseBool(options.RTFReadOnlyDocuments);
    }

    static createFromSecuritySettings(settings) {
        const userSecuritySettings = new UserSecuritySettings(settings);
        const settingsList = _.map(new DocumentUserPermissions(), (v, k) => k);
        const userPermissions = userSecuritySettings.findValues(settingsList);
        return new DocumentUserPermissions(userPermissions);
    }
}

export class DocumentEditorModel {
    constructor(options) {
        options = options || {};

        this.orderId = options.orderId || 0;
        this.loanID = options.loanID || 0;
        this.cssMainID = options.cssMainID || 0;
        this.invoiceID = options.invoiceID || 0;
        this.checkID = options.checkID || 0;
        this.depositSlipID = options.depositSlipID || 0;
        this.sendChecksToFileScan = _.parseBool(options.sendChecksToFileScan);
        this.fileScanCheckCategory = _.parseNumber(options.fileScanCheckCategory, null);

        const docs = options.documents || [];
        this.documents = _.map(docs, d => new DocumentModel(d));

        this.userPermissions = new DocumentUserPermissions();
    }

    get orderDocs() { return _.map(this.documents, d => d.toMergeDocumentModel()); }

    toMergeRequest() {
        return new MergeRequest(this);
    }
}

export class MergeDocumentModel {
    constructor(options) {
        options = options || {};
        this.orderDocumentId = options.orderDocumentId || null;
        this.promptValues = options.promptValues || null;
        this.promptValueDetails = options.promptValueDetails || [];
    }
}

export class MergeRequest {
    constructor(options) {
        options = options || {};
        this.clientKey = options.clientKey || 0;
        this.orderId = options.orderId || 0;
        this.cssMainID = options.cssMainID || 0;
        this.loanID = options.loanID || 0;
        this.invoiceID = options.invoiceID || 0;
        this.checkID = options.checkID || 0;
        this.depositSlipID = options.depositSlipID || 0;
        this.noSignaturePrint = options.noSignaturePrint || null;
        this.mergeEngineId = options.mergeEngineId || 1;
        this.isCheckCoverLetter = _.parseBool(options.isCheckCoverLetter, false);
        this.sendChecksToFileScan = _.parseBool(options.sendChecksToFileScan, false);
        this.fileScanCheckCategory = _.parseNumber(options.fileScanCheckCategory, null);
        this.returnType = options.returnType || "wopi";

        const docs = options.orderDocs || [];
        this.orderDocs = _.map(docs, d => new MergeDocumentModel(d));
    }

    splitRequest() {
        if (this.orderDocs.length <= 1) return [this];
        const requestList = [];
        _.forEach(this.orderDocs, d => {
            requestList.push(new MergeRequest({
                clientKey: d.clientKey,
                orderId: this.orderId,
                cssMainID: this.cssMainID,
                loanID: this.loanID,
                invoiceID: this.invoiceID,
                checkID: this.checkID,
                depositSlipID: this.depositSlipID,
                sendChecksToFileScan: this.sendChecksToFileScan,
                fileScanCheckCategory: this.fileScanCheckCategory,
                orderDocs: [d]
            }));
        });
        return requestList;
    }
}

export class MergeResult {
    constructor(options) {
        options = options || {};
        this.orderDocumentId = options.orderDocumentId || null;

        this.content = null;
        if (options.content) {
            this.content = _.isBase64String(options.content) ? options.content : btoa(options.content);
        }

        this.succeeded = options.succeeded || false;
    }
    get orderDocumentID() { return this.orderDocumentId; }
}

export class SaveDocumentViewModel {
    constructor(options) {
        options = options || {};
        this.orderId = _.parseNumber(options.orderId, 0);
        this.loanID = _.parseNumber(options.loanID, 0);
        this.cssMainID = _.parseNumber(options.cssMainID, 0);
        this.invoiceID = _.parseNumber(options.invoiceID, 0);

        this.saveAll = options.saveAll || false;
        this.saveLocation = options.saveLocation || 1;
        this.fileScanDocumentDuplicateAction = _.parseNumber(options.fileScanDocumentDuplicateAction, 0);
        this.fileType = _.parseNumber(options.fileType, 0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.fileScanDescription = options.fileScanDescription || "";
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, 0);
        this.tagIDs = options.tagIDs;
        this.publish = options.publish || false;
        this.requiresAttention = _.parseBool(options.requiresAttention, false);

        this.allowAnonymousAccess = options.allowAnonymousAccess || false;
        this.documentDescription = options.documentDescription || "";

        //this.roleAccessList = options.roleAccessList || [];

        //this.userPermissions = new DocumentUserPermissions(options.userPermissions);
    }

    static get SaveLocation() {
        return {
            Local: 1,
            FileScan: 2
        };
    }

    static getSaveLocationOptions(canAttachFilesAndDocuments) {
        if (canAttachFilesAndDocuments)
            return [{ id: 1, name: "Local" }, { id: 2, name: "Document Management" }];
        else
            return [{ id: 1, name: "Local" }];
    }
}

export class RoleDocumentAccessModel {
    constructor(options) {
        options = options || {};
        this.isAllowed = options.isAllowed || false;
        this.rolesID = options.rolesID || null;
        this.contactID = options.contactID || null;
        this.companyID = options.companyID || null;
        this.buyerSellerID = options.buyerSellerID || null;
        this.pcEnableAccess = options.pcEnableAccess || false;
        this.roleTypeID = options.roleTypeID || null;
        this.tableID = options.tableID || null;
        this.relationship = options.relationship || null;
        this.roleTypeName = options.roleTypeName || null;
        this.companyName = options.companyName || null;
        this.contactName = options.contactName || null;
        this.countyName = options.countyName || null;
        this.emailAddress = options.emailAddress || null;
        this.phone = options.phone || null;
        this.cellPhone = options.cellPhone || null;
        this.regionDisplay = options.regionDisplay || null;
    }
}

export class DocumentDownloadModel {
    constructor(options, baseUrl, persist=false) {
        options = options || {};
        baseUrl = baseUrl || "";
        this.clientKey = _.uniqueId("doc_dnld");
        this.cacheKey = options.cacheKey || "invalid";
        this.ordersID = options.ordersID || null;
        this.orderDocumentID = options.orderDocumentID || null;
        this.documentTemplateID = options.documentTemplateID || null;
        this.fileScanDocumentID = options.fileScanDocumentID || null;
        this.fileScanPagesID = options.fileScanPagesID || null;
        this.pathInfo = options.pathInfo || null;
        this.fileName = options.fileName || null;
        this.fileDescription = options.fileDescription || null;
        this.fileType = options.fileType || null;

        this.isValid = _.isBoolean(options.isValid) ? options.isValid : true;
        this.errorMessage = options.errorMessage || null;

        let url = options.downloadUrl || `documents/download/${this.cacheKey}/${this.fileName}`;
        this.downloadUrl = `${baseUrl}/${url}${persist ? "?persist=true" : ""}`;
    }

    get fileNameWithTimeStamp() {
        let ext = DocumentFileType.fileExtension(this.fileType);
        let fileName = _.trimEnd(this.fileName, ext);
        return `${fileName}_${DateTimeHelper.now('yyyy-M-dd_hh-mm-ss-a')}${ext}`;
    }
}

export class FileScanDocumentSaveRequest {
    constructor(options) {
        options = options || {};

        this.ordersID = options.ordersID || 0;
        this.fileType = _.parseNumber(options.fileType, 0);
        this.fileScanDocumentDuplicateAction = _.parseNumber(options.fileScanDocumentDuplicateAction, 0);
        this.fileScanCategoryID = _.parseNumber(options.fileScanCategoryID, 0);
        this.fileScanDescription = options.fileScanDescription || "";
        this.fileScanDescriptionID = _.parseNumber(options.fileScanDescriptionID, 0);
        this.tagIDs = options.tagIDs;

        const docs = options.documents || [];
        this.documents = _.map(docs, d => new DocumentModel(d));
    }
}

export class DocumentSaveRequest {
    constructor(options, storeContentAsBytes=false) {
        options = options || {};

        this.orderId = options.orderId || 0;
        this.orderWorkflowTaskID = options.orderWorkflowTaskID || 0;
        this.fileName = options.fileName || null;
        this.fileDescription = options.fileDescription || null;
        this.outputFileType = options.outputFileType || 7;
        this.overwriteExisting = options.overwriteExisting || false;
        this.documentIds = options.documentIds || [];

        const docs = options.documents || [];
        this.documents = _.map(docs, d => {
            let doc = new DocumentModel(d);
            if(!storeContentAsBytes) return doc;
            doc.fileBytes = doc.fileContent;
            doc.fileContent = null;
            return doc;
        });
    }

    trimContent() {
        const clone = _.cloneDeep(this);
        _.forEach(clone.documents, d => { d.fileContent = ""; });
        return clone;
    }
}

export class DocumentSaveResult {
    constructor(options) {
        options = options || {};
        this.name = options.name || null;
        this.extension = options.extension || null;
        this.fileName = options.fileName || null;
        this.fileContentResult = options.fileContentResult || null;
        this.success = options.success || false;
        this.message = options.message || null;

        const docs = options.documents || [];
        this.documents = _.map(docs, d => new DocumentModel(d));

        this.downloadUrls = options.downloadUrls || [];

        const downloads = options.documentDownloads || [];
        this.documentDownloads = _.map(downloads, d => new DocumentDownloadModel(d));
    }
}

export class DocNavItemModel extends ProgressItem {
    constructor(options) {
        super(options);
        this.sequence = _.parseNumber(options.sequence, 0);
        this.disabled = _.parseBool(options.disabled);
        this.closeable = _.parseBool(options.closeable, true);
        this.printed = _.parseBool(options.printed, false);
        this.edited = _.parseBool(options.edited, false);
    }

    get hasError() { return this.status === ProgressItem.status.failure; }
}

export class ArcIntegrationModelDto {
    constructor(options) {
        options = options || {};

        this.assetId = options.assetId || null;
        this.name = options.name || null;
        this.description = options.description || null;
        this.fileId = options.fileId || null;
        this.filestoreInfoUri = options.filestoreInfo?.uri || options.filestoreInfoUri || null;
        this.states = options.states || [];
        this.modifiedDate = options.modifiedDate || null;
        this.versionNumber = options.latestVersionNumber || null;
    }
}
export class ArcDocumentModel {
    constructor(options) {
        options = options || {};

        this.existingDocumentTemplates = options.existingDocumentTemplates || [];
        this.newDocumentTemplates = options.newDocumentTemplates || [];
    }
}

export { DocumentFileType, SaveDocumentAction, ContentDataFormat, FileScanDocumentType, ProgressItem, TabItem };
